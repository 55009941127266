var AET = window.AET || (function ($) {
        'use strict';

        var a_ajax = new AwesomeAjax('aet');

        var slider_fallbacks = function (count) {
            return [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: count,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: count < 8 ? count : 8,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: count < 6 ? count : 6,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: count < 5 ? count : 5,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 667,
                    settings: {
                        slidesToShow: count < 4 ? count : 4,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 568,
                    settings: {
                        slidesToShow: count < 3 ? count : 3,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: count < 2 ? count : 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ];
        };

        function call_slider(selector, options) {
            return jQuery(selector).slick(options);
        }

        function enable_slider(id, options) {
            id = parseInt(id);
            var count = parseInt(options.count),
                autoplay = options.autoplay == 1 ? true : false || false,
                speed = parseInt(options.speed),
                adaptive = options.adaptive == 1 ? true : false || false,
                selector = '#aet-layout-' + id + ' > .slider-enabled',
                options = {
                    accessibility: false,
                    speed: 1000,
                    draggable: true,
                    infinite: true,
                    slidesToShow: count,
                    mobileFirst: true,
                    slidesToScroll: count,
                    autoplay: autoplay,
                    autoplaySpeed: speed,
                    adaptiveHeight: adaptive,
                    prevArrow: '<div type="button" title="Previous" class="aet-button slick-prev"><svg viewBox="0 0 26 26"><use xlink:href="#icon-arrow-left"></use></svg></div>',
                    nextArrow: '<div type="button" title="Next" class="aet-button slick-next"><svg viewBox="0 0 26 26"><use xlink:href="#icon-arrow-right"></use></svg></div>',
                    responsive: slider_fallbacks(count)
                };
            window['aet_slider_' + id] = call_slider(selector, options);
        }

        function enable_slider_photo(id, options) {
            id = parseInt(id);
            var count = parseInt(options.count),
                autoplay = options.autoplay == 1 ? true : false || false,
                speed = parseInt(options.speed),
                adaptive = options.adaptive == 1 ? true : false || false,
                selector = '#aet-layout-' + id + ' .testimonials-photo',
                options = {
                    accessibility: false,
                    speed: 1000,
                    draggable: true,
                    slidesToShow: count,
                    mobileFirst: true,
                    slidesToScroll: count,
                    variableWidth: true,
                    infinite: true,
                    autoplay: autoplay,
                    autoplaySpeed: speed,
                    adaptiveHeight: adaptive,
                    lazyLoad: 'progressive',
                    prevArrow: '<div type="button" title="Previous" class="aet-button slick-prev"><svg viewBox="0 0 26 26"><use xlink:href="#icon-arrow-left"></use></svg></div>',
                    nextArrow: '<div type="button" title="Next" class="aet-button slick-next"><svg viewBox="0 0 26 26"><use xlink:href="#icon-arrow-right"></use></svg></div>',
                    responsive: slider_fallbacks(count)
                };
            window['aet_slider_photo_' + id] = call_slider(selector, options);
            $(selector).on('afterChange', function (event, slick, direction) {
                var index = slick.currentSlide;
                var item = $('#aet-layout-' + id + ' .testimonials-photo li[data-slick-index="' + index + '"]');
                select_testimonial(item);
                setTimeout(function () {
                    calc_short_text_nubbin_position(id);
                }, 1);

            });
        }

        function init_filter(id) {
            id = parseInt(id);
            var aet_filter = new AwesomeFilter({
                container: $('[group-id="' + id + '"].filter-container'),
                controls: $('[group-id="' + id + '"].filter-controls'),
                item_selector: '.isotope-item',
                control_selector: '.aet-button',
                control_active: 'aet-button-active',
                attribut: 'group-id',
                id: id
            });
            aet_filter.init();
        }

        function init_search(id, slider) {
            $('#aet-layout-' + id + ' [data-action="testimonials-search"]').on('input', function () {
                var query = $(this).val().trim().toLowerCase();

                if(slider){
                    if(window['aet_slider_' + id] !== undefined){
                        window['aet_slider_' + id].slick('slickUnfilter');
                        window['aet_slider_' + id].slick('slickFilter', function () {
                            var title = $(this).data("title").toLowerCase() || "";
                            if (title.indexOf(query) !== -1) {
                                return true;
                            }
                            return false;
                        });
                    }
                    if(window['aet_slider_photo_' + id] !== undefined){
                        window['aet_slider_photo_' + id].slick('slickUnfilter');
                        window['aet_slider_photo_' + id].slick('slickFilter', function () {
                            var title = $(this).data("title").toLowerCase() || "";
                            if (title.indexOf(query) !== -1) {
                                return true;
                            }
                            return false;
                        });
                    }

                }else{
                    var $group = $(this).parents('.aet-layout').get(0);
                    var testimonials = $('ul[group-id].testimonials-box > li', $group);
                    testimonials.hide();
                    $.each(testimonials, function (index, item) {
                        var title = $(item).data("title").toLowerCase() || "";
                        if (title.indexOf(query) !== -1) {
                            $(item).show();
                        }
                    });
                }
            });

            $('#aet-layout-' + id + ' [data-action="clear-search"]').on('click', function () {
                $('#aet-layout-' + id + ' [data-action="testimonials-search"]').val('').trigger('input');
            });
        }

        function bind_profiles(id) {
            id = parseInt(id);
            $('[group-id="' + id + '"] [aet-profile]').on('click', function () {
                var url = $(this).attr('data-profile');
                window.open(url, '_self');
            });
        }

        function init_animations(id) {
            id = parseInt(id);
            var $container = $('#aet-layout-' + id);
            $container.addClass('aet-animations-enabled');

            AwesomeViewport.add({
                element: $container[0],
                threshold: '30%',
                enter: function () {
                    $(this).addClass('aet-transition__in');
                }
            });

            $container.find('li').each(function (i, el) {
                var $t = $(this);
                var name = get_name($t.find('.testimonial_name .group-field-content').text());
                if (!name) return;
                $(this).data('name', name).find('.photo-container').attr('data-name', 'Meet &#xa; ' + name).parent().append('<span class="aet-img-caption"><strong>Meet</strong><br>' + name + '</span>');
            });
        }

        function get_name(str) {
            var result;
            // get first word, assuming it's first name
            var index = str.indexOf(" ");
            result = index === -1 ? str : str.substring(0, index);

            return result;
        }


        function init_panel(id, animation) {
            id = parseInt(id);
            $('[group-id="' + id + '"].testimonials-box li[testimonial-id]').on('click', function (e) {
                // if clicked on single showcase photo or on outer link
                if ($(this).attr('data-showcase-block') == 'photo' || e.target.tagName == 'A' || $(e.target).parent().get(0).tagName == 'A') {
                    return;
                }
                var group_id = $(this).parents('.testimonials-box').attr('group-id');
                var testimonial_id = $(this).attr('testimonial-id');
                var prefix = group_id + '_' + testimonial_id;

                if (typeof window['aet_' + prefix + '_panel'] != 'object' && !window['la_panel_opened']) {
                    var aet_panel = new AwesomePanel({
                        id: 'aet_' + prefix,
                        direction: 'left',
                        width: '500px',
                        height: '100%',
                        animation: animation,
                        html: '#aet_testimonial_panel_' + prefix
                    });
                    aet_panel.init();
                }
            });
        }

        function init_summary_panel(id, animation) {
            $('#aet-summary-' + id + ' .submit').on('click', function (e) {
                var $parent = $(this).parents('.aet-summary'),
                    id = $parent.attr('id').replace_all('aet-summary-', '');
                // if clicked on single showcase photo or on outer link
                if (typeof window['aet_summary' + id + '_panel'] != 'object' && !window['la_panel_opened']) {
                    var aet_panel = new AwesomePanel({
                        id: 'aet_summary_' + id,
                        direction: 'left',
                        width: '500px',
                        height: '100%',
                        animation: animation,
                        html: $('.aet-testimonial-panel', $parent)
                    });
                    aet_panel.init();
                }
            });
        }

        function select_testimonial(item) {
            var parent = $(item).parents('.aet-layout').eq(0);
            var items = $(item).siblings();
            var blocks = ['name', 'position', 'short_text', 'divider', 'source', 'rating', 'social'];
            var testimonial = item.attr('testimonial-id');
            items.removeClass('active');
            item.addClass('active');
            $.each(blocks, function (index, block) {
                $('.testimonials-' + block + ' > li', parent).hide().removeClass('active');
                $('.testimonials-' + block + ' > li[testimonial-id="' + testimonial + '"]', parent).show().addClass('active');
            });
        }

        function init_showcase(id) {
            id = parseInt(id);
            var parent = $('#aet-layout-' + id);
            var items = $('.testimonials-photo li', parent);
            $(items).on('click', function (e) {
                var item = $(this);
                select_testimonial(item);
                calc_short_text_nubbin_position(id);
            });
        }

        function calc_rating_icons_size(id) {
            var group = $('#aet-layout-' + id);
            $('.testimonial_rating .group-field-content', group).css('max-width', 'auto');

            var on_resize_handler = function () {
                var testimonials = $('li .testimonial_rating', group),
                    fill_max_width = $('li .testimonial_rating:visible', group).outerWidth();
                fill_max_width = Math.round(fill_max_width / 10) * 10;
                $('.group-field-content', testimonials).css('max-width', fill_max_width);
                var icon_max_width = $('li .rating-background:visible > *', group).outerWidth();

                $(testimonials).each(function (index, testimonial) {
                    var rating = parseInt($(testimonial).attr('data-rating'));
                    $('.rating-fill', testimonial).css('max-width', fill_max_width * rating / 100);
                    $('.rating-background > *', testimonial).each(function (index, item) {
                        $(item).css('max-height', icon_max_width);
                    });
                    $('.rating-fill > *', testimonial).each(function (index, item) {
                        $(item).css('max-width', icon_max_width);
                        $(item).css('max-height', icon_max_width);
                    });
                });
            };
            on_resize_handler();
            $(window).resize(function () {
                on_resize_handler();
            });
        }

        function calc_social_icons_size(id) {
            var group = $('#aet-layout-' + id);
            $('.testimonial_social .group-field-content', group).css('max-width', 'auto');

            var on_resize_handler = function () {
                var testimonials = $('li .testimonial_social', group) || $('.testimonials-social > li', group),
                    container = $('.testimonial_social', group),
                    container_showcase = $('.testimonials-social', group),
                    fill_max_width = container.outerWidth() === 0 ? container_showcase.outerWidth() : container.outerWidth(),
                    icon_max_width,
                    count = 0;
                fill_max_width = Math.round(fill_max_width / 10) * 10;

                $('.group-field-content', $(testimonials)).css('max-width', fill_max_width);

                $(testimonials).each(function (index, item) {
                    var icons = $('.group-field-content > a', item);
                    count = icons.length > count ? icons.length : count;
                });

                icon_max_width = fill_max_width / count;
                var origin_size = $(testimonials).css('font-size');

                if(origin_size && +AwesomeUtil.clean_number(origin_size) > icon_max_width){
                    $(testimonials).css('font-size', icon_max_width);
                    $(testimonials).each(function (index, item) {
                        var icons = $('.group-field-content > a', item);
                        $(icons).each(function (index, item) {
                            $(item).css('font-size', icon_max_width);
                        });
                    });
                }
            };
            on_resize_handler();
            $(window).resize(function () {
                on_resize_handler();
            });
        }

        function calc_short_text_nubbin_position(id) {
            var selector = '#aet-layout-' + id;
            var group = $(selector);

            var on_resize_handler = function () {
                var active = $('.testimonials-photo .slick-track li.active:not(.slick-cloned), .testimonials-photo .slick-track li.active', group),
                    short_texts = $('.testimonials-short_text', group),
                    short_text = $('.active .testimonial_short_text', short_texts),
                    photos = $('ul.testimonials-photo', group),
                    content_box = $('.group-field-content', short_text),
                    $nubbin = $('.nubbin', short_text),
                    testimonial_id = active.attr('testimonial-id'),
                    group_width = group.outerWidth(),
                    group_offset = group.offset()['left'],
                    active_width = active.outerWidth(),
                    active_offset = active.offset()['left'],
                    short_text_width = short_texts.outerWidth(),
                    nubbin_offset = active_offset - group_offset + active_width / 2 - 17 - (group_width - short_text_width) / 2,
                    blocks_offset = active_offset - group_offset + active_width / 2 - group_width / 2;
                $nubbin.css('left', 'auto');
                $('li[testimonial-id="' + testimonial_id + '"] .nubbin', short_texts).css('left', nubbin_offset);
                $('.level-2 ul.testimonials-box:not(.testimonials-photo)', group).css('left', blocks_offset);

                if ($nubbin.offset().left < content_box.offset().left) {
                    content_box.css('min-width', photos.outerWidth());
                }
            }
            setTimeout(function () {
                on_resize_handler();
            }, 14);
            $(selector + ':not(.aet-bound)').addClass('aet-bound').on('resize', function () {
                on_resize_handler();
            });
        }

        function calculate_slider_items_size(id, count) {
            var selector = '.aet-layout-single_showcase[id="aet-layout-' + id + '"]';
            var group = $(selector);
            var on_resize_handler = function () {
                var slider = $('.slick-list', group),
                    slider_width = slider.outerWidth(),
                    items = $('.slick-slide', group),
                    item_max_width = slider_width / count;
                items.css('max-width', item_max_width);

            }
            on_resize_handler();
            $(selector + ':not(.aet-slider-bound)').addClass('aet-slider-bound').on('resize', function () {
                on_resize_handler();
            });
        }

        function bind_rating_select(id) {
            var form = $('#aet-form-' + id),
                $parent,
                $fill,
                $input,
                block_width,
                rating;
            $(document).on('mouseover', '#aet-form-' + id + ' .testimonial_rating .skill-value', function (e) {
                var mouse_x = e.pageX - $(this).offset().left;
                $parent = $(this).parents('.testimonial_rating');
                $fill = $(this).find('.rating-fill');
                $input = $parent.find('[name="rating"]');
                block_width = $(this).outerWidth();
                rating = Math.ceil(mouse_x * 100 / block_width / 10) * 10;

                $fill.css('width', rating + '%');
            });

            $(document).on('mouseout', '#aet-form-' + id + ' .testimonial_rating .skill-value', function (e) {
                rating = $input.val();
                $fill.css('width', rating + '%');
            });

            $(document).on('click', '#aet-form-' + id + ' .testimonial_rating .skill-value', function (e) {
                $input.val(rating);
                $fill.css('width', rating + '%');
            });
        }


        // Submission form submit
        function new_testimonial_from_form(id) {
            $(document).off('click', '[data-aet-form-id="' + id + '"] .aet-form-block-submit');
            $(document).on('click', '[data-aet-form-id="' + id + '"] .aet-form-block-submit', function (e) {
                e.preventDefault();

                var $shortcode = $(this).parents('[data-aet-form-id="' + id + '"]'),
                    $form = $('form', $shortcode),
                    $wrapper = $('.aet-form-wrap', $shortcode),
                    $button = $('.aet-form-block-submit', $shortcode),
                    $success = $('.aet-form-success', $shortcode),
                    $spinner = $('.a-spinner', $shortcode),
                    $alert = $('.aet-form-alert', $shortcode),
                    redirectURL = $shortcode.attr('data-redirect');

                var data = $form.serialize(),
                    hide_form = function () {
                        $('input, textarea', $form).prop('disabled', true);
                        $wrapper.removeClass('fade-animation-in');
                        $spinner.addClass('fade-animation-in');
                    },
                    show_form = function () {
                        $('input, textarea', $form).prop('disabled', false);
                        $wrapper.addClass('fade-animation-in');
                        $spinner.removeClass('fade-animation-in');
                    },
                    hide_button = function () {
                        $button.removeClass('fade-animation-in');
                    },
                    show_button = function () {
                        $button.addClass('fade-animation-in');
                    },
                    hide_success = function () {
                        $success.removeClass('fade-animation-in');
                    },
                    show_success = function () {
                        $spinner.removeClass('fade-animation-in');
                        $success.addClass('fade-animation-in');
                        $success.find('.progress').css('width', '100%');
                    };


                $alert.hide();
                hide_form();
                hide_button();

                a_ajax.ajax({
                    action: 'aet_new_testimonial_from_from',
                    data: {
                        data: data
                    },
                    success_handler: function (data) {
                        show_success();
                        if (typeof grecaptcha !== 'undefined') {
                            var widget_id = window['g_recaptcha_' + id];
                            grecaptcha.reset(widget_id);
                        }
                        setTimeout(function () {
                            hide_success();
                            show_button();
                            show_form();
                            if (redirectURL !== '') {
                                window.location.href = redirectURL;
                            }
                        }, 3000);
                    },
                    success_error_handler: function (errors) {
                        var html = '<div>';
                        $.each(errors, function (index, value) {
                            html += '<p>' + value.message + '</p>';
                        });
                        html += '</div>';
                        $alert.show().html(html);
                        show_form();
                        show_button();
                    },
                    error_handler: function (data) {
                        $alert.show().html('<div>' + data + '</div>');
                        show_form();
                        show_button();
                    }
                });

            });
        }

        function bind_testimonial_form_button() {
            var load_content = function (form) {
                    var dfd = $.Deferred();
                    a_ajax.ajax({
                        action: 'aet_get_form_html',
                        data: {
                            id: form.id
                        },
                        success_handler: function (data) {
                            data = '<div class="aet-testimonial-panel aet-testimonial-panel-empty_white a-slide-panel-content-wrapper">\
                                        <div class="a-scroll">\
                                            <div class="aet-testimonial-panel-container">\
                                                <div class="aet-testimonial-panel-body">' +
                                                    data +
                                                '</div>\
                                            </div>\
                                        </div>\
                                    </div>';
                            dfd.resolve(data);
                        }
                    });
                    return dfd;
                };
            window['aet_localize']['submit_selectors'].forEach(function (item, i, arr) {
                var selector = item.selector || 'aet_submission_form_trigger_' + item.id;

                load_content = load_content.bind(this);
                $(document).off('click', selector);
                $(document).on('click', selector, function (e) {
                    e.preventDefault();
                    var args = {
                        id: 'aet_',
                        direction: 'left',
                        width: '500px',
                        height: '100%',
                        animation: item.panel_animation,
                        load_content: load_content(item)
                    };
                    window.panel = new AwesomePanel(args);
                    panel.init();
                });
            });
        }

        function bind_summary_resize(id) {
            var summaries = $('.aet-summary').toArray(),
                breakpoints = [480, 667];
            var on_resize_handler = function () {
                summaries.forEach(function (summary, i, arr) {
                    var $summary = $(summary);
                    if ($summary.attr('id') != 'aet-summary-' + id) return;

                    var width = $summary.outerWidth();
                    AwesomeUtil.removeClass($summary, 'aet-breakpoint-');
                    breakpoints.forEach(function (item, i, arr) {
                        if (width <= item) {
                            $summary.addClass('aet-breakpoint-' + item);
                        }
                    });
                });
            };

            on_resize_handler();
            $(window).resize(function () {
                on_resize_handler();
            });
        }

        bind_testimonial_form_button();

        return {
            'enable_slider': enable_slider,
            'enable_slider_photo': enable_slider_photo,
            'init_animations': init_animations,
            'init_filter': init_filter,
            'init_search': init_search,
            'init_panel': init_panel,
            'init_summary_panel': init_summary_panel,
            'bind_profiles': bind_profiles,
            'calc_rating_icons_size': calc_rating_icons_size,
            'calc_social_icons_size': calc_social_icons_size,
            'init_showcase': init_showcase,
            'calc_short_text_nubbin_position': calc_short_text_nubbin_position,
            'calculate_slider_items_size': calculate_slider_items_size,
            'new_testimonial_from_form': new_testimonial_from_form,
            'bind_testimonial_form_button': bind_testimonial_form_button,
            'bind_rating_select': bind_rating_select,
            'bind_summary_resize': bind_summary_resize
        };
    })(jQuery);